
import Container from '~/components/layout/Container'
import VButton from '~/components/ui/VButton'
import { ACADEMY_COURSE } from '~/constants/api'

export default {
    async asyncData({ $axios }) {
        const res = await $axios.get(ACADEMY_COURSE)
        return {
            items: res.data.items
        }
    },
    data() {
        return {
            items: []
        }
    },
    computed: {
        course() {
            return this.items[0]
        }
    },
    methods: {
        join() {
            this.$modal.show('academy-join-modal', {
                course: this.course
            })
        },
    },
    components: {
        Container,
        VButton
    }
}
